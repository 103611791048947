#menu-selector{
  float: right;
  position: relative;
  padding: 18px 15px 15px 15px;
  border-radius: 50%;
  .user-container {
    position: relative;
    .user-image{
      border-radius: 50%;
      background-color: rgba(237, 120, 71, 0.8);
      text-align: center;
      align-self: center;
      line-height: 32px;
      font-weight: 600;
      font-family: "Work Sans";
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      width:32px;
      height: 32px;
      &:hover {
        background-color: #ed7847;
        cursor: pointer;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      }
    }
  }


}
