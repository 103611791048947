#pool-diagram-tile-static{
  background: #fff;
  padding: 20px;
  height: 300px;
  width: 66%;
  display: inline-flex;
  flex-flow: column;
  position: relative;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;

  .inner-box-pd{
    display: inline-flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

  }

  .pool-image-container{
    background-color: #FFFFFF;
    position: absolute;
    height: 250px;
    display: flex;
    right:30px;
    justify-content: center;
    .img-pool-st{
      height:250px;
    }
    .swimmers-layout{
      width:100%;
      height: 100%;
      top:0;
      position: absolute;
      overflow: hidden;
      .dot{
        background-color: #57A6DB;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        position: absolute;
      }
    }
  }

  .pool-tile-title{
    width: fit-content;
    white-space: nowrap;
    position: relative;
    font-size: 1.5rem;
  }
}