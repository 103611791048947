#reports-table-page {
  display: flex;
  justify-content: center;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  .container {
    border-radius: 5px;
    background: #fff;
    height: 440px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    position: relative;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
    .cell-title{
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    .cell-text{
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .MuiTableCell-body{
      border-right: 1px solid #E6E8EB;
    }
    .MuiTableCell-head{
      border-right: 1px solid #E6E8EB;
    }
  }
}