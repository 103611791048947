#reports-header {
  background-color: #00173D;
  height: 80px;
  width: 100%;
  color: #F9F7F4;
  font-size: 18px;
  border-bottom: 1px solid #3D5484;
  top: 0;
  z-index: 1;
  display: inline-flex;
  position: absolute;

  .company-icon {
    cursor: pointer;
    max-height: 80px;
    max-width: 160px;
    padding: 5px 20px 0 0px;
    float: left;
  }

  .pool-selection-area {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pageSelectionButton {
      border-radius: 30px;
      width: fit-content;
      padding: 0 20px ;
      height: 30px;
      text-align: center;
      font-size: 16px;
      line-height: 28px;
      color: #cfcfcf;
    }

    .selected {
      background-color: #57a6db;
      color: #ffffff;
      transition: all 130ms cubic-bezier(0.955, 0.48, 0.725, 0.14);

    }
  }
  .vertical-separator{
    height: 34px;
    width: 1px;
    position: absolute;
    right: 75px;
    top: 6px;
    border-left: 1px solid #727A82;
    margin-top:16px;
  }
  .lx-icon-container{
    width: 200px;
    height: 75%;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-self: end;
    font-size: 16px;
    position: absolute;
    right: 70px;
    .prefix-title{
      margin-top: 17px;
    }
    .image-lx{
      max-width: 120px;
      max-height: 50px;
    }

  }
  .right {
    float: right;
  }

  .left {
    float: left;
  }
}