#reports-graph-page{
  display: flex;
  justify-content: center;
  min-height: 200px;
  max-height: 260px;
  height: 100vh;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 2.5%;
  border-radius: 5px;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  .container {
    border-radius: 5px;
    background: #fff;
    padding: 20px;
    max-height: 250px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    position: relative;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
    .custom-tooltip{
      background: #fff;
      padding: 10px;
      box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
      display: flex;
      flex-direction: column;
      .tooltip-row{
        display: inline-flex;
        justifyContent: space-between;
        .label{
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.3px;
          color: #00173D;
          margin-right: 10px;
        }
        .item{
          ont-family: 'Work Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.3px;
          text-align: right;
          color: #00173D;
        }
      }

    }
    .tile-title {
      width: fit-content;
      white-space: nowrap;
      position: absolute;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #00173D;
    }
  }
}