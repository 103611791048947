#connected-watches-page{
  display: flex;
  flex-direction: column;
  .container {
    margin-bottom: 5%;
    background: #fff;
    padding: 20px;
    height: 250px;
    position: relative;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
    border-radius: 5px;
    .tile-title {
      width: fit-content;
      white-space: nowrap;
      position: absolute;
    }
    .graph-container{
      width: 100%;
      .tooltip-container {
        border-radius: 5px;
        width: 50mm;
        min-height: 16mm;
        height: fit-content;
        padding: 2mm;
        background: white;
        box-shadow: 0px 2px 14px rgba(14, 19, 37, 0.15);
        display: flex;
        flex-flow: column;
        z-index: 1;
        justify-content: space-between;
        .tooltip-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .inner-box {
          z-index: 1;
          display: inline-flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          padding: 2mm 0;

          .item-name {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.3px;
            color: #3A3A3A;
            float: left;
          }

          .item-value {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: flex-start;
            text-align: right;
            letter-spacing: 0.3px;

            color: #3A3A3A;
            position: relative;
            float: right;
          }
        }
      }
    }
    height: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
  }
}