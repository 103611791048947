#tabs {
  height: 100%;
  margin-top: 80px;
  max-width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    }
  .tabs-header {
    display: flex;
    justify-content: flex-start;
    height: 55px;
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.14);
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }

  .content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 88%;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .box {
    cursor: pointer;
    width:fit-content;
    padding: 0 20px;
    max-width: 140px;
    text-align: center;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #727A82;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
    &:first-child{
      margin-left: 10mm
    }
    .inner-box {

      display: flex;
      width: fit-content;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5mm;

      .label {
        text-align: center;
        width: max-content;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        color: inherit;
      }

      .dot {
        margin: 2mm 2mm 2mm 0;
        height: 2mm;
        width: 2mm;
        border-radius: 1mm;
        top: 5mm
      }
      .offline{
        background: #bebbbb;
      }
      .error {
        background: #D13333;
      }
      .online {
        background: #4AD133;
      }
      .warning{
        background: #fab402;
      }
    }
  }
  .alerts-temp-disabled{
    background-color: rgba(250, 250, 0, 0.4);
    color: #c7c751 !important;
  }
  .selected-box {
    width: max-content;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgba(30, 24, 42, 1);
    color: #00173D;
    font-weight: 600;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
    margin-left: 5mm;
    margin-right: 5mm;
    max-width: 250px;
    &:first-child{
      margin-right: unset !important;
    }
  }
}

