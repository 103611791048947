.lifeguard-item-container{
  width:100%;
  display: flex;
  color:white;
  flex-direction: row;
  .right-part{
    width: 100%;
    display: flex;
    color:white;
    flex-direction: row;
    align-items: center;
    gap:3%;
  }
  .left-part{
    width: 40%;
    display: flex;
    color:white;
    flex-direction: row;
    align-items: center;
    gap:15%;
    justify-content: space-between;
    .icon-container{
      height: 40px;
      width: 40px;
      display: flex;
      align-items: flex-end;
    }
  }
}

.actions-row {
  display: flex;
  gap: 12px;
  margin-right: 17px;
  padding-bottom: 12px;
}
.delete-actions-row{
  width: 100%;
  display: flex;
  margin-top: 40px;
  gap: 12px;
  justify-content: center;
}


.lifeguard-pane{
  padding-top: 12px;
  width: 100%;
  height: fit-content;
  display: flex;
  gap:24px 24px;
  flex-direction: column;
  justify-content: space-around;
  background-color: transparent;
  .text-fields-row{
    display: flex;
    flex-direction: row;
    gap:24px;
  }

}

.MuiFormHelperText-root.Mui-error{
  margin-right: -5px;
  margin-left:2px;
  text-align: left;
}