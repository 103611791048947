#dashboard-page {
  background: #F7F8FC;
  font-family: Work Sans;
  height: calc(100% - 5px);
  display: flex;
  flex-direction: column;

  .main-pane {
    display: -ms-flexbox; /* For Edge */
    display: flex;
    -ms-flex-direction: column; /* Edge */
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    .icon-svg {
      padding-right: 10px;
    }

    .title-container {
      position: absolute;
      left: 0;
      top: -35px;
      line-height: 28px;
      display: inline-flex;
      justify-content: space-between;
      font-size: 26px;
      font-weight: 700;
      height: 32px;
      width: 100%;
      z-index: 1;

      .title-inner-con {
        display: inline-flex;
        justify-content: flex-start;
      }

      .header-container-right {
        height: 100%;
        width: 560px;
        right: 0;
        position: absolute;
        display: inline-flex;
        white-space: nowrap;
        justify-content: flex-end;
        border-radius: 2mm;

        .vertical-separator {
          height: 28px;
          width: 1px;
          padding-right: 5mm;
          vertical-align: center;
          border-left: 1px solid #E6E8EB;
        }

        .title-icon {
          margin: 0;
          padding-right: 5mm;
          align-self: center;
          color: #00173D;
        }

        .marginTop-4 {
          margin-top: -4px;
        }

        .header-count-container {
          padding: 0 0mm 0 0mm;
          width: fit-content;
          display: inline-flex;
          justify-content: flex-end;

          .marginTop-1 {
            margin-top: -1px;
          }

          .marginTop-7 {
            margin-top: -7px;
          }

          .header-value {
            font-weight: 600;
            font-size: 18px;
            padding: 0 5mm 0 0;
          }
        }
      }
    }

    .margin-op {
      top: -34px;
    }

    .pool-tile-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .at-edge {
        margin-right: unset !important;
      }
    }

    .pane {
      margin: auto;
      flex: 1 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .marginTop20 {
      margin-top: 20px;
    }

    .bottom {
      bottom: 30px;
    }

    .full-height {
      min-height: 80%;
      height: fit-content;
      flex-direction: column;
      justify-content: space-between;
    }

    .top175 {
      top: 47mm;
    }

    .history-pane {
      width: 100%;
      height: 60%;
      position: relative;
      display: inline-flex;
      justify-content: space-between;
      flex-flow: row;

      .inner-pane-history-pane {
        width: 100%;
        height: fit-content;
        max-height: 325px;
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        align-content: space-between;

        .title-container-general {
          position: relative;
          padding-top: 8px;
          padding-bottom: 8px;
          line-height: 28px;
          display: inline-flex;
          justify-content: space-between;
          font-size: 26px;
          font-weight: 700;
          height: 32px;
          width: 100%;
          z-index: 1;

          .title-inner-container {
            display: inline-flex;
            justify-content: flex-start;
            width: fit-content;
          }
        }
      }
    }

    .max270 {
      max-height: 270px;
    }

    .height100 {
      min-height: 100%;
    }

    .inner-pane {
      display: inline-flex;
      position: relative;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-flow: row;
      height: fit-content;
      width: 90%;
      margin: 58px auto auto;

      .responsive-ui-container {
        height: 250px;
        flex-grow: 1.2;
        max-width: 26%;
        min-width: 285px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        align-content: center;
      }
    }

    .top-container {
      flex-wrap: wrap;
      flex: 1;
      justify-content: flex-start;
    }
  }
}
