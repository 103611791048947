#live-section{
width: 100%;
  .live-section-container{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    flex: 1 1;
    justify-content: flex-start;
    padding-bottom: 50px;

    .pools-container{
      width: inherit;
      display: inline-flex;
    }
    .flex-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .is-last{
        margin-right: unset;
      }
    }

  }
  .spread-between{
    justify-content: space-between;
  }

}