#pool-general-tile{
  background: #ffffff;
  display: inline-flex;
  height: fit-content;
  position: relative;
  margin-right: 1.3333333333%;
  margin-top: 12px;
  border-radius: 5px;
  min-width: 320px;
  max-width: 24%;
  width: 24%;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  .shadow-light{
    box-shadow: 4px 3px 8px 1px rgba(100, 100, 100, 0.05);
  }
  .alerts-temp-disabled-icon{
    width: 50px;
    height: 50px;
    right: 18px;
    top: -85px;
    position: absolute;
  }
  .vertical-separator{
    height: 180px;
    width: 1px;
    border-left: 1px solid #E6E8EB;
    margin-top:10mm;
  }
  .right-label-container{
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
    margin-top:27.5mm

  }

  .general-label-container {
    height: fit-content;
    width: fit-content;
    text-align: center;
    position: relative;
    padding:5px;

    .risk-sub-title {
      height: 14px;
      font-weight: 500;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #727A82;
    }
    .risk-main-title {
      font-weight: 500;
      font-size: 50px;
      color: #00173D;
      text-align: center;
      letter-spacing: 3px;
    }
  }

}