#site-settings-drawer {
  .title {
    display: inline-flex;
    justify-content: space-between;
    background-color: #001333;
    border-bottom: solid 1px #3D5484;
    font-size: 1.52rem;
    font-weight: 500;
    padding: 22px 24px 22px 24px;
    color: #EAFBFF;
  }

  .exit-button {
    margin-top: 4px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
    &:hover{
      background-color: rgb(1, 45, 117);
      color: #9a0825;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
    }
  }


  .settings-paper {
    width: 43vw;
    overflow: hidden;
    height: 50rem;
    background-color: #001333;
  }

}