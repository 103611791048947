#sort-label{
  display: inline-flex;
  .title-text{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  .arrows-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .active{
      color: #00173D;
      margin: -4px;
    }
    .arrow{
      margin: -4px;
      color: #8C939A;

      &:hover{
        color: #00173D;
        cursor: pointer;
      }
    }

  }


}