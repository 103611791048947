#heatmap-page {
  background: #fff;
  padding: 20px;
  height: 325px;
  width: calc(100vw - 40px);
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  margin-bottom: 0.5%;
  .pool-image-container {
    position: relative;
    width: 100%;
    top: 5%;
    display: flex;
    .img-pool{
      left: 20%;
      position: absolute;
      height: 300px;
    }
    .swimmers-layout {
      position: absolute;
      left: 20.5%;
    }
  }
  .top-container {
    .tile-title {
      width: fit-content;
      white-space: nowrap;
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin-top: unset;
    }
    .gradient-container{
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;
      position: absolute;
      right: 4%;
      .plain-text{
        position: unset;
        padding: unset;
        margin: unset;
        margin-inline: 3%;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #00173D;
      }
      .color-tape {
        display: flex;
        .square {
          width: 24px;
          height: 24px;
        }
      }
    }
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1%;
    position: relative;
  }
}

#heatmap-container{
  height: 100%;
}