#main-header-static{
background-color: #00173D;
  height: 80px;
  width: 100%;
  color: #F9F7F4;
  top: 0;
  z-index: 1;
  position: absolute;
  .company-icon{
    height: 65px;
    padding:6px 0 0 0;
    float:left;
  }
  .vertical-separator{
    height: 34px;
    width: 1px;
    vertical-align: center;
    border-left: 1px solid #727A82;
    margin-top:22px;
  }
  .right{
    float: right;
  }
  .left{
    float: left;
  }
}