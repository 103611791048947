#total-swimmers-tile-general{
  background: #ffffff;
  height: 44%;
  position: relative;
  flex-grow: 0.3;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  .icon{
    height: 80px;
    position: absolute;
    top:0;
    padding: 5.5mm 4mm ;
  }
.value-title-container{
  text-align: center;
  top: 20%;
  position: relative;
  .value-title{
    font-weight: 500;
    font-size: 50px;
  }
  .bottom-title{
    font-weight: 500;
    font-size: 14px;
    color: #727A82;
  }
}
}