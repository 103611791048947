#line-tile{
  padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    display: flex;
  justify-content: space-around;
  width: calc(99.7% - 20px);
white-space: nowrap;
  .tile-field {
    font-size: 1rem;
    width: 100%;
  }

}
#line-tile-header{
  display: flex;
  justify-content: space-between;
  width: calc(100% - 50px);
  padding:15px 25px;
  background-color: #f7f8fc;
  .tile-field-header{
    font-size: 1.1rem;
    font-weight: 600;
    width: 100%;
  }
}