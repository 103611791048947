#page-404{
    margin:0;
    padding:0;
    overflow:hidden;


  .header-page-404 {
    font-family: 'Nunito', sans-serif;
    color: #2D3965;
    text-align: center;
    padding: 2em 0 0;
    opacity: 0;
  }

  svg {
    position:fixed;
    bottom:0;
    left:0;
    height: 100%;
    width:100%
  }
}