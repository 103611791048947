#pool-diagram-tile{
  background: #ffffff;

  padding: 20px;
  flex: 1;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  position: relative;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;

  .admin-button{
    width: fit-content;
    height: fit-content;
    background-color: #9398a1;
    position: absolute;
    top: 0;
    left:45%;
    padding: 3px 2mm;
    font-size: 12px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(100, 100, 100, 0.5);
    &:hover{
      box-shadow: 1px 1px 4px 1px rgba(100, 100, 100, 0.57);
    }
    &:active{
      box-shadow: 0px 0px 3px 1px rgba(100, 100, 100, 0.67);
      background-color: #454549;
      transition: all 100ms cubic-bezier(0.42, 0, 0.91, 0.41);
    }
  }

  .inner-box-pd{
    display: inline-flex;
    padding: 0;
    align-items: center;
    min-width: 625px;
  }

  .pool-image-container{
    width: 400px;
    position: absolute;
    display: flex;
    right:30px;
    justify-content: center;
    .img-pool{
      height:200px;
    }
    .swimmers-layout{
      width:100%;
      height: 100%;
      top:0;
      position: absolute;
      overflow: hidden;
      .dot{
        background-color: #57A6DB;
        border-radius: 50%;
        height: 8px;
        width: 8px;
        position: absolute;
      }
      .admin-dot{
        background-color: #f62b2b;
        height: 7px;
        width: 7px;
        position: absolute;
      }
      .purple{
        background-color: #7100ff;

      }
      .yellow{
        background-color: #ecab07;
      }

    }
  }
  .green{
    background-color: #43cc1c;
  }
  .red{
    background-color: #e11;
  }

  .pool-tile-title{
    width: fit-content;
    white-space: nowrap;
    position: relative;
  }
}