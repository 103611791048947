#company-name{
    width: 50%;
    height: 30px;
    margin-top:27px;
    display: inline-flex;
    .user-title{
        padding: 0 5mm;
        font-size: 18px;
        color: white;
    }
    .margin-o{
        margin-top:-4px;
    }
  }