#language-selector{
  width:40px;
  height: 65px;
  float: right;
  padding: 7px 10px 0 15px;
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

  .language-container{
    color: whitesmoke;
    display: flex;
    width: fit-content;
    justify-content: space-between;
    img{
      max-height: 35px;
    }
    .selected-language-button{
      width: 32px;
      height: 32px;
      font-size: 16px;
      border-radius: 20px;
      line-height: 35px;
      text-align: center;
      color: #00173D;
      font-weight: 550;
      background-color: #E9ECF7;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      &:hover{
        background-color: #ec9a77;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      }
    }
    .language-label{
      padding: 5px;
    }
  }
}