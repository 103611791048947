#reports-pool-utilization-page {
  width: 100%;
  background-color: #00173D;
  margin-top: 80px;
  display: flex;
  flex-flow: column;

  .top-pane {
    width: 65%;
    position: relative;

    .top-menu {
      height: 55px;
      display: flex;
      align-content: flex-end;
      flex-direction: row;
      flex-wrap: wrap;
      color: #57A6DB;
      position: relative;
      padding-left: 50px;
      .spinner-container {
        position: relative;
        left: 50px;
        height: 34px;
        width: 105px;
      }
      .button-group {
        max-width: 75px;
        max-height: 34px;
        padding-right: 30px ;
      }

      .pool-name-title {
        font-size: 24px;
        font-weight: 500;
      }
    }

    .graph-area-rp {
      height: 20em;
      padding: 15px 0 0 0;
      position: relative;

      .day-selection-area {
        display: inline-flex;
        justify-content: space-between;
        height: 36px;
        width:calc(100% - 50px) ;
        padding-left: 50px;

        .main-title {
          color: #EAFBFF;
          font-size: 22px;
          font-weight: 500;
        }

        .day-select {
          display: flex;
          align-items: center;

          .my-buttons-group {
            border-radius: 4px;
            border: solid 1px #9DADBC;
            display: inline-flex;
            height: 38px;
            align-items: center;

            .day-selection-button {
              min-width: 35px;
              transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

              .icon {
                color: #EAFBFF;
              }
              &:hover{
                background-color: #122b56;
                transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

              }
            }

            .separator {
              width: 1px;
              border-right: 1px solid #9DADBC;
              height: 70%;
            }

            .select-cus {
              height: 38px;
              width: 180px;
              box-shadow: none !important;
              transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;


              &:hover {
                box-shadow: none !important;
                background-color: #122b56;
                transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

              }
            }
          }

          .day-title {
            font-size: 14px;
            color: #9DADBC;
            align-items: center;
            padding-right: 15px;
          }
        }
      }

      .graph-pane {
        height: 100%;
        padding-left: 50px;
        position: relative;

        .custom-tooltip {
          padding: 15px;
          border: 1px #f5f5f5 solid;
          border-radius: 4px;
          background-color: #00173D;
          color: #f5f5f5;

          .title {
            font-size: 14px;
            padding-bottom: 5px;
          }

          .tooltip-container {
            display: flex;
            flex-flow: column;

            &:last-child {
              padding-bottom: 5px;
            }
          }

          .label-container {
            display: inline-flex;
            align-items: center;
            padding-top: 5px;

            .dot {
              background-color: #EEE8A9;
              width: 5px;
              height: 5px;
              border-radius: 5px;
              &:hover{
                width: 5px;
                height: 5px;
                transform: scaleX(2.1) scaleY(2.1);
              }
            }

            .red {
              background-color: #d92d2d;
            }

            .yellow {
              background-color: #ecdf56;
            }

            .blue {
              background-color: #57A6DB;
            }

            .label {
              padding-left: 5px;
              font-size: 12px;
            }
          }
        }


        .legend-title {
          font-size: 14px;
          color: #9DADBC;
          padding-right: 10px;
          padding-left: 3px;
        }

        .recharts-legend-wrapper {
          margin-left: 33px;
        }

        .line-chart {
          padding-top: 30px;
          margin-left: -33px;
        }

      }
    }
  }

  .right-floating-pane {
    width: 33%;
    height: 100%;
    right: 0;
    z-index: 2;
    position: fixed;
  }


  .bottom-pane {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    min-height: 290px;
    height: 40%;
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: center;

    .alert-image {
      height: 280px;
      max-width: 600px;
    }

    .alert-info {
      width: 100%;
      height: 100%;
      display: inline-flex;

      .left-alert-pane {
        width: 24%;
        min-width: 320px;
        display: flex;
        flex-direction: column;
        margin-top: 5%;

        .container {
          width: 260px;
          height: 60px;
          margin: 0px 0px 35px 84px;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;


        }
      }

      .right-alert-pane {
        width: 45%;
        margin-top: 2%;

      }
    }


  }
}