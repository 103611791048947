#pool-capacity-tile-static {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 5px;
svg{
  height: 100%;
  width: 100%;
}
  .label-container {
    height: fit-content;
    width: 43%;
    position: absolute;
    margin:57px;
    text-align: center;
    .title-inner {
      height: 14px;
      font-weight: 500;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #727A82;
    }
    .count-label {
      font-weight: 500;
      font-size: 50px;
      color: #00173D;
      text-align: center;
      letter-spacing: 3px;
    }
  }
}