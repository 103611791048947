#site-settings {
  .title {
    padding: 24px 2px;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    color: #EAFBFF;
    border:none;
  }
  .form-container {
    gap: 10px;
    padding: 24px;
    display: flex;
    flex-flow: column;
    width: 39vw;
    .title-edit-container {
      width: 39vw;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      .buttons-container{
        display: inline-flex;
        justify-content: space-between;
      }
      .expand-horizontal{
        transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        width: 150px;
      }
      .collapse-horizontal{
        transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1) 100ms;
        overflow: hidden;
        width: 0;
      }
      .save-icon{
        border-radius: 20px;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        width: 1px;
      }
      .active{
        width: 24px;
        cursor: pointer;
        padding-right: 3px;
        &:hover{
          transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
          box-shadow: 0px 0px 4px 2px #3D5484;
        }
        &:active{
          box-shadow: 0px 0px 2px 1px #3D5484;
          transition: all 60ms cubic-bezier(0.42, 0, 0.58, 1) 1ms;
        }
      }

      .red{
        color: #f3204a;
      }
      .green{
        color: #00b61a;
      }
    }
    .width17vw{
      width: 17.8vw;

    }
    .text-input {
      color: #EAFBFF;


      .MuiOutlinedInput-notchedOutline{
        border-color: #3D5484;
        border-width: thin;
      }

       .Mui-error fieldset{
        border-color: #f3204a !important;
      }

    }

    .checkbox-container {
      display: inline-flex;
      width: fit-content;
      color: #EAFBFF;
      .switch {
        padding-left: 0;
        width: 45px;

        .MuiSwitch-thumb {
          background-color: #fff;
          margin: 4px 0 0 2px;
        }

        .MuiSwitch-switchBase {
          left: -10px;
        }

        .MuiSwitch-track {
          background-color: #9DADBC;
          opacity: 1;
          height: 22px;
          border-radius: 30px;
        }

        .Mui-checked {
          left: -19px;
        }

        .Mui-checked + .MuiSwitch-track {
          background-color: #57A6DB;
          opacity: 1;
        }
      }

      .switch-title {
        line-height: 46px;
      }
    }
    .contact-container{
      display: flex;
      flex-flow: column;
      overflow:hidden;
      .container-row{
        justify-content: space-between;
        display: inline-flex;
      }
      .phone-selector-rap{
        position: relative;
        width: 50%;
        height: 76px;
        .phone-error-msg{
          color: #f3204a;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.66;
          letter-spacing: 0.03333em;
          text-align: left;
          margin-top: 3px;
          margin-left: 2px;
        }
      }
      .justify-right{
        justify-content: flex-end;
      }

    }
    .top15{
      margin-top:25px;
    }

    .expand{
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 1ms;
      height: 450px;
      width: 39vw;
    }
    .collapse{
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 100ms;
      height: 0;
      overflow: hidden;
      width: 30vw;
    }
    .expand2{
      transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1) 1ms;
      height: 38px;
      width: 39vw;
    }
    .collapse2 {
      transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1) 100ms;
      height: 0;
      overflow: hidden;
    }

  }
}
