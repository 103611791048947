#transformation-grid-tile{
  position: relative;
  background: #ffffff;
  padding: 10px;
  height: 240px;
  text-align: center;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  z-index: 2;
  max-width: 20%;
  .grid-item{
    position: relative;
    text-align: center;
    color: red;
    width: 25px;
    font-size: 12px;
  }
  .grid-button{
    position: relative;
    width: 15px;
    height: 25px;
    color: #3F82aF;
  }
  .save-button{
    display: inline-block;
    cursor: pointer;
    margin: 20px 0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #3F82aF;
    border: 1px solid #018dc4;
    font: normal 16px/normal "Times New Roman", Times, serif;
    color: rgba(255,255,255,0.9);
    text-overflow: clip;
    box-shadow: 5px 3px 8px 2px rgba(100, 100, 100, 0.2);

    &:hover{
      transition: all 150ms cubic-bezier(0.42, 0, 0.58, 1);
      background-color: #018dc4;
    }
    &:active{
      box-shadow: 4px 2px 8px 1px rgba(100, 100, 100, 0.2);
      background-color: #c13d44;

    }
  }
}