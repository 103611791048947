#active-watches-tile-static {
  background: #fff;
  padding: 20px;
  width: 360px;
  height: 340px;
  max-height: 300px;
  text-align: center;
  position: relative;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;

  .title {
    text-align: left;
    font-size: 1.5rem;
  }
  .color-blue {
    color: #086db0;
    font-weight: 400;
  }

  .container {
    width: 100%;
    margin-top: 10px;
    height: 90%;
    display: flex;
    flex-flow: column;
    gap: 2%;


    .empty-title {
      height: 30%;
      color: #860e0e;
      margin: auto;
      font-size: 1.5rem;
      text-align: center;
    }

    .watch-item {
      height: 55px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5%;
      background-color: #fff;
      border-radius: 5px;

      svg {
        height: 45px;
        width: 45px;
        border-radius: 50px;
        box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);

      }

      .watch-icon {
        align-self: center;
      }

      .watch-name {
        padding: 0 10px;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .horizontal-separator {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #E6E8EB;
  }
}