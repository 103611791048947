#date-selector {
  width: 250px;
  height: 38px;
  background: transparent;
  align-items: center;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #3D5484;
  cursor: pointer;
  position: relative;

  .input-title {
    height: 90%;
    color: #AABCCC;
    width: 100%;
    z-index: 3;
  }

  .display {
    color: #EAFBFF;
  }


  .c-pane {
    gap: 10px;
    padding: 24px;
    display: flex;
    flex-flow: column;
    width: 360px;
    height: 300px;
    position: absolute;
    overflow: hidden;
    transition: all 200ms cubic-bezier(0.25, 0.25, 0.75, 0.75) 10ms;

    .close-icon {
      border-radius: 50%;
      position: absolute;
      transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      right: 25px;
      top: 25px;
      color: #d92d2d;

      &:hover {
        background: rgba(107, 148, 232, 0.43);
        transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      }
    }
  }

  .calendar {
    padding-top:10px;
    border-radius: 5px;
  }

  .expand {
    z-index: 1;
    right: -385px;
    top: -25px;
    transition: all 100ms cubic-bezier(0.25, 0.25, 0.75, 0.75) 50ms;

  }

  .collapse {
    z-index: -1;
    height: 0;
    width: 0;
    right: 0;
    top: 0;
    transition: all 100ms cubic-bezier(0.25, 0.25, 0.75, 0.75) 10ms;

    .rdrNextPrevButton {
      width: 0px;
      height: 0px;
    }
  }
}