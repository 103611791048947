#reset-password-new-cred-page {
  background: #fff;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  .header {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 93.89%;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.5);
    background: #E5E5E5;
  }

  .bottom-label {
    font-weight: 500;
    font-size: 18px;
    align-items: center;
    display: flex;
    position: absolute;
    height: fit-content;
    bottom: 2.5mm;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

    .prefix {
      margin-top: 2px;
    }

    .svg {
      margin-top: -5px;
      margin-left: 10px;
    }
  }

  .text-pane {
    background-color: white;
    width: fit-content;
    margin-bottom: -50px;
    .title-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: #00173D;
      padding-bottom: 10px;
    }

    .explain-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      text-align: center;
      color: #00173D;
    }

    .password-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      padding-bottom: 5px;
    }
  }

  .back-button {
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 40px;
    width: 312px;
    height: 48px;
    background: #00173D;
    border-radius: 4px;
    &:disabled{
      background: rgba(0, 23, 61, 0.56);
    }
    &:hover {
      cursor: pointer;
    }

    .button-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 130%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
.top40{
  margin-top: 2rem;
}
  .password-strong-label{
    font-weight: 600;
    padding: 2px 11px;
  }
  .Weak{
    color: #ff7300;
  }
  .Too{
    color: crimson;
  }
  .Strong{
    color: #25c009;
  }
  .Medium{
    color: #fcc404;
  }
  .input-container {
    position: relative;
    width: 312px;
    height: 48px;
    .password-input {
      position: absolute;
      box-sizing: border-box;
      width: 312px;
      height: 48px;
      background: none;
      color: #231F20;
      border: 1px solid #8C939A;
      border-radius: 4px;
      z-index: 1;
      padding: 0 10px;
      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: none;
      }
    }

    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label {
      opacity: 1;
      font-size: 14px;
      line-height: 16px;
      background: #fff;
      z-index: 2;
      padding-left: 4px;
      top: -8px;
      left: 10px;
      padding-right: 4px;
    }

    .floating-label {
      position: absolute;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      width: max-content;
      background: none;
      color: #8C939A;
      transition: 0.2s ease all;
      left: 10px;
      top: 15px;
    }

    .floating-visibility {
      position: absolute;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      width: max-content;
      background: none;
      color: #8C939A;
      right: 10px;
      top: 13px;
      z-index: 2;
      cursor: pointer;
    }

    .username-error {
      position: absolute;
      height: fit-content;
      padding: 1px 11px;
      margin-top: 50px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #D02E26;
      width: max-content;
      max-width: 312px;
      left: 0px;
    }
  }

}

