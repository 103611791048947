#footer {
  //background: #ff4b59;
  width: 100%;
  position: absolute;

  .bottom-label {
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    display: flex;
    padding:10px 0 5px 0;
    height: fit-content;
    justify-content: center;
    position: absolute;
    bottom:0;
    z-index: 2;
    width: 100%;
    overflow: hidden;
    background-color: #F7F8FC;

    .prefix {
      margin-top: 2px;
      padding: 5px 0;
    }

    svg {
      margin-top: -5px;
      margin-left: 10px;
    }
  }
}