#date-picker {
  display: inline-flex;
  flex-wrap: nowrap;
  cursor: pointer;
  border-radius: 5px;
  background: #E9ECF7;
  width: 340px;
  justify-content: space-between;
  padding: 0 5px 0 5px;
  height: 40px;
  right: 0;
  margin-top: -18px;
  align-items: center;
  position: relative;
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
  &:hover {
    background: #e6e6f0;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
  }

  .calendar-title {
    display: inline-flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 0 5px 0 5px;

    .date-value {
      font-size: 16px;
      font-weight: 500;
      align-self: center;
      width: 110px;
      padding: 1.5mm;
    }
    .date-icon-arrow {
      padding: 0 18px 0 18px;
      align-self: center;
    }
  }

  .calendar-tile {
    border-radius: 5px;
    width: fit-content;
    height: max-content;
    position: absolute;
    top: 25%;
    z-index: 1;
    background: #ffffff;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
    right: 0;
    .margin-holder{
      background: transparent !important;
    }
    .bottom-menu {
      display: flex;
      border-radius:  5px ;
      background-color: #fff;
      flex-direction: row;
      position: relative;
      .range {
        position: relative;
        padding: unset;
        border-radius: 5px;
        width: 55%;
        .rdrStaticRanges {
          flex-direction: row;
          padding: 2px 0;
          .rdrStaticRange{
            align-self: center;
            .rdrStaticRangeLabel{
              padding: 3px 4px;
              width: max-content;
              &:hover{
                border-radius: 3px;
              }
            }
          }
          .rdrStaticRangeSelected{
            color: #57A6DB !important;
          }
        }

        .rdrInputRanges {
          display: flex;
          flex-direction: unset;
          flex-flow: unset;
          justify-content: flex-start;
          padding: 5px 0;
          .rdrInputRange {
            width: fit-content;
            padding: 0 5px;
          }
          .rdrInputRange:last-child{
            display: none;
          }
        }
      }

      .bottom-container {
        position: relative;
        right: 0;
        bottom: 10px;
        width: 100%;
        display: inline-flex;
        justify-content: space-evenly;
        align-items: flex-end;
      }

      .button {
        box-sizing: content-box;
        width: 140px;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        border: none;
        font-size: 12px;
        border-radius: 15px;
        height: 32px;
      }

      .cancel {
        border: 1px solid #52A1CA;
        color: #52A1CA;
        text-overflow: clip;
        font-weight: 1000;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Work Sans';
        box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        &:hover {
          box-shadow: 3px 2px 4px 1px rgba(100, 100, 100, 0.2);
          transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
          background: rgba(73, 72, 72, 0.1);
        }
      }

      .submit {
        color: #fff;
        background: rgba(87, 166, 219, 0.8);
        text-overflow: clip;
        font-weight: 1000;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Work Sans';
        box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        &:hover {
          box-shadow: 3px 2px 4px 1px rgba(100, 100, 100, 0.2);
          transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
          background: rgba(87, 166, 219, 1);
        }
      }
    }
    .calendar {
      border-radius: 5px;
      cursor: default;
      width: 100%;
      .rdrDateDisplayWrapper {
        display: none;
      }

      .rdrMonthAndYearWrapper {
        height: 50px;
        .rdrNextPrevButton {
          background-color: unset;

          &:hover {
            transition: all 350ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
            background-color: #EFF2F7;
            border-radius: 50%;
          }
        }

        .rdrPprevButton {
          position: absolute;
          right: 2em;
        }

        .rdrMonthAndYearPickers {
          border: #dadada solid 1px;
          border-left: unset;
          width: fit-content;
          flex: unset;
          display: flex;
          height: 38px;
          border-radius: 0 20px 20px 0;

          .rdrMonthPicker {
            color: #000;
            margin: unset;
            padding: unset;
            select {
              &:hover {
                transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
                background-color: rgba(0, 0, 0, 0.07);
              }
              transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
              padding: 5px;
              height: 100%;
              width: fit-content;
              color: inherit;
              font-weight: bold;
              font-size: 25px;
              border-radius: unset;
              background: #fff;
              border: none;
              option {
                font-weight: normal;
                font-size: 15px;
                border-radius: 15px;
              }
            }
          }

          .rdrYearPicker {
            color: rgba(46, 46, 46, 0.8);
            margin: unset;
            padding: unset;
            height: 100%;
            select {
              &:hover {
                transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
                background-color: rgba(0, 0, 0, 0.07);
              }
              transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
              padding: 10px;
              color: inherit;
              height: 100%;
              font-weight: bold;
              font-size: 15px;
              border-radius: 0 20px 20px 0;
              background: #fff;
              border: none;

              option {
                font-weight: normal;
                font-size: 15px;
                border-radius: 15px;
                padding: unset;
              }
            }
          }
        }
      }

      .rdrMonths {
        .rdrMonth{
          padding: 0 0.633em 0.5em;
          font-size: 11px !important;
          width: 26em;
          .rdrMonthName {
            display: none;
          }
          .rdrWeekDays{
            .rdrWeekDay{

            }
          }
          .rdrDays{
            .rdrDay{
              height: 2.75em;
              .rdrDayNumber{
                padding: unset;
                span:after {
                  height: 1px;
                }
              }
            }
          }
        }

      }
    }
  }
}