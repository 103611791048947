#reset-password-page {
  display: flex;
  background: #fff;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  .header {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 93.89%;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.5);
    background: #E5E5E5;
  }

  .bottom-label {
    font-weight: 500;
    font-size: 18px;
    align-items: center;
    display: flex;
    position: absolute;
    height: fit-content;
    bottom: 2.5mm;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

    .prefix {
      margin-top: 2px;
    }

    .svg {
      margin-top: -5px;
      margin-left: 10px;
    }
  }

  .text-pane {
    background-color: white;
    width: fit-content;

    .title-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: #00173D;
    }

    .explain-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      text-align: center;
      color: #00173D;
    }

    .password-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      padding-bottom: 5px;
    }
  }

  .back-button {
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 40px;
    width: 312px;
    height: 48px;
    background: #00173D;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }

    .button-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 130%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 0;
      background: #00173D;
    }
  }

  .input-container {
    display: flex;
    justify-content: center;
    margin-top: -100px;
    width: 312px;
    height: 48px;

    position: relative;

    .password-input {
      position: absolute;
      box-sizing: border-box;
      width: 312px;
      height: 48px;
      background: #FFF;
      color: #231F20;
      border: 1px solid #8C939A;
      border-radius: 4px;
      padding: 0 10px;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .username-input {
      position: absolute;
      box-sizing: border-box;
      width: 312px;
      height: 48px;
      background: #FFF;
      border: 1px solid #8C939A;
      border-radius: 4px;
      color: #8C939A;
      padding: 0 10px;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: none;
      }
    }

    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label {
      opacity: 1;
      margin-top: -8px;
      margin-left: -205px;
      font-size: 14px;
      line-height: 16px;
      background: #fff;
      padding-left: 4px;
      padding-right: 4px;
    }

    .floating-label {
      position: absolute;
      margin-left: -215px;
      margin-top: 14px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #8C939A;
      flex: none;
      order: 0;
      flex-grow: 0;
      position: absolute;
      pointer-events: none;
      transition: 0.2s ease all;

    }

    .username-error {
      position: absolute;
      display: flex;
      height: fit-content;
      top: 100%;
      left: 0;
      padding-top: 5px;
      width: max-content;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #D02E26;
    }
  }

}

