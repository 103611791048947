#swimmers-count-history-tile {
  background: #fff;
  padding: 20px;
  height: 250px;
  flex-grow: 0.7;
  position: relative;
  max-width: 70%;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  .container {
    display: flex;
    justify-content: flex-start;
    position: relative;
    .tooltip-container {
      border-radius: 5px;
      width: 50mm;
      min-height: 16mm;
      height: fit-content;
      padding: 2mm;
      background: white;
      box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .tooltip-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      .inner-box {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 2mm 0;

        .item-name {
          font-weight: 400;
          font-size: 14px;
        }

        .item-value {
          font-weight: 600;
          font-size: 15px;
        }
      }
    }

    .tile-title {
      width: fit-content;
      white-space: nowrap;
      position: absolute;
    }

    .more-action {
      float: right;
      top: 0;
      right: 0;
      color: #727A82;
      cursor: pointer;
      position: absolute;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

      &:hover {
        background: #eee;
        border-radius: 25px;
      }
    }
  }
}