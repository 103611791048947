.react-international-phone-country-selector{
  background-color: transparent;

}
.react-international-phone-country-selector-button{
  z-index: 100;
  background-color: transparent;
  height: 100%;
}
.react-international-phone-country-selector-button:hover{
  background-color: transparent;
  height: 100%;
  border-color:#3D5484;
}

.react-international-phone-input{
  background-color: transparent !important;
  color:white !important;
  height: 100% !important;
  width: 100% ;
  font-size: 16px !important;
}

.phone-error-msg{
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-left: 2px;
}
