#table-pagination-actions{
  display: inline-flex;
  right: 10%;
  position: relative;
  align-items: center;
  .actions-plain-text{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    color: #8C939A;
    width: fit-content;
    display: inline-flex;
  }
  .page-input{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #00173D;
    background: #FFFFFF;
    border: 1px solid #E6E8EB;
    border-radius: 5px;
    width: 60px;
  }
  .page-number {

  }
  .page-number-active {

  }

  .border-cube{
    background: #FFFFFF;
    border: 1px solid #E6E8EB;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #8C939A;
    background: #FFFFFF;
    text-align: center;
    &:hover{
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 1px solid #E9ECF7;
      cursor: pointer;
    }
  }
  .border-cube-active{
    background: #FFFFFF;
    border: 1px solid #57A6DB;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    background: #FFFFFF;
    color: #57A6DB;
    &:hover{
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 1px solid #57A6DB;
      cursor: pointer;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

