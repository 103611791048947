@keyframes wobble {
  0% {
    transform: scaleX(1) scaleY(1);
  }

  100% {
    transform: scaleX(13) scaleY(13);
  }
}

#reports-diagram-pane {
  height: 100%;
  display: flex;
  justify-content: center;

  .pool-image-container {
    background-color: #002852;
    width: 400px;
    min-height: 587px;
    position: absolute;
    border-radius: 4px;
    padding: 28px;
    margin-top: 42px;
    display: flex;
    flex-flow: column;

    .pool-view-title {
      color: #EAFBFF;
      font-size: 22px;
      font-weight: 500;
    }
    .heatmap-container {
      align-self: center;
      transform: rotateZ(90deg);
      margin-top: 140px;

      .alerts-pane {
        width: 96%;
        height: 85%;
        position: absolute;
        z-index: 1;
        left: 2%;
        top: 7%;


        .alert-point {
          animation: wobble 0.7s infinite;
          height: 1px;
          width: 1px;
          position: absolute;
          border-radius: 15px;
        }
      }

      .swimmers-layout {
        position: relative;
        .heatmap-canvas{
          left: 7px !important;
          top: 7px !important;
        }
      }

      .img-pool {
        z-index: 2;
        position: relative;
        height: 100%;
        width: 100%;
      }

    }
    .legend-container{
      position: absolute;
      display: inline-flex;
      justify-content: space-between;
      bottom:25px;
      width: 50%;
      align-self: center;
      .label{
        font-size: 14px;
        color: #9DADBC;
        line-height: 21px;
        padding: 0 10px ;
      }
      .gradient{
        width: 120px;
        height: 24px;
        background: linear-gradient(90deg, rgb(0, 23, 61) 10%, rgb(65, 143, 222) 60%, rgb(154, 204, 239) 90%);
      }


    }
  }
}
