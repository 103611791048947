#login-page {
  width: 100%;
  font-family: "Work Sans", serif;
  background: #F7F8FC;
  overflow: hidden;
  display: inline-flex;
  height: 100%;
  overflow: hidden;

  .lang-selector {
    position: absolute;
    right: 0;
    background: transparent;
    z-index: 1;
  }

  .left-pane {
    width: 27%;
    height: 100%;
    background: #1f172aff;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .lx-logo-container {
      margin-top: -15mm;
      padding: 10px;

      .lx-logo {
        width: 100%;
      }
    }
  }

  .right-pane {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.rotate{
      transform: rotateX(180deg)rotateY(180deg)rotateZ(180deg);
      transition: transform 500ms ease;
    }

    .form-container {
      position: relative;
      //top: 25%;
      width: 480px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      color: #1f172a;
      box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.15);
      .Mui-selected{
        color: #1f172a;
        border-radius: 10px 10px 0 0;
      }

      .invisible{
        opacity: 0;
        transition: opacity 500ms ease;
      }
      &.mfa{
        height: fit-content;
        transition: all 500ms ease;
      }

      .switch-container {
        padding: 10px 20px;
        height: fit-content;
        display: flex;
        justify-content: space-between;

        .title {
          width: fit-content;
          line-height: 30px;
          font-weight: 600;
          font-size: 1.6em;
          padding-bottom: 10px;
        }
      }

      .form {
        width: 100%;
        display: inline-grid;
        position: relative;
        justify-content: center;
        height: 300px;

        .fp-container {
          display: inline-flex;
          justify-content: flex-end;

          .link-fp {
            font-size: 1em;
            position: relative;
            text-align: end;
            top: 0;
            width: fit-content;
            height: fit-content;
          }
        }

        .paddingB {
          padding-bottom: 10px;
        }
        .paddingT60{
          padding-top:60px;
        }

        .mini-container {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          width: 100%;
          height: 75px;

          label {
            font-size: 1.15em;
            font-weight: 500;
          }

          .input-field {
            width: calc(100% - 20px);
            height: 45px;
            padding-left: 20px;
            font-size: 1.1em;
            font-weight: 500;
            border: 0;
            border-radius: 5px;
            display: inline-block;
            box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.15);
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.66);
            transition: all 200ms cubic-bezier(0.42, 0, 0.91, 0.41);
          }

          .errorState {
            border: 1px solid #ed7847;

          }
        }
      }
    }
  }

  .submit-button {
    align-self: flex-end;
    width: 370px;
    height: 28px;
    cursor: pointer;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-weight: 800;
    font-family: "Work Sans", serif;
    font-size: 1.2em;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    text-overflow: clip;
    letter-spacing: 1px;
    background: #1f172a;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
    transition: all 200ms cubic-bezier(0.42, 0, 0.91, 0.41);

    &:hover {
      background: #2f273a;
    }

    &:active {
      box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.4);
      background: #2f272a;
    }
  }
  .button-inactive{
    font-size: 16px;
    line-height: 28px;
    background: #422c64;
    box-shadow: 0px 0px 0px 0px rgba(100, 100, 100, 0.02);
    &:hover {
      background: #422c64;
    }
  }
}