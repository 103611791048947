#active-watches-tile{
  background: #fff;
  padding: 20px;
  min-width: 200px;
  height: 220px;
  max-height: 300px;
  text-align: center;
  position: relative;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
.more-action{
  float:right;
  color: #727A82;
  cursor: pointer;
  position:absolute;
  right:20px;
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
  &:hover{
    background: #eee;
    border-radius: 25px;
  }
}
  .title{
    text-align: left;
  }
  .container{
    width:100%;
    margin-top: 10px;
    height: 90%;
    color:#727A82;

    scrollbar-width: none;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      display: none;
    }
    .empty-title{
      height: 30%;
      padding-top: 43%;
      text-align: center;
    }
    .watch-item{
      height: 28px;
      padding: 25px 5px;
      display: inline-flex;
      .dot {
        height: 10px;
        width: 10px;
        margin-top:9px;
        border-radius: 5px;
        background: #4AD133;
      }
      .watch-icon{
        align-self: center;
      }
      svg{
        padding: 0 10px;
      }
      .watch-name{
        padding: 0 10px;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
  .horizontal-separator{
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #E6E8EB;
  }
}