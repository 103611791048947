#mfa-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding:0 5% 5% 5%;
  width: 100%;
  height: 100%;
  gap:20px;
  .exit-button-wrapper{
    width: 100%;
    box-sizing: border-box;
    padding-top: 3%;
    .back-button{
      color: #555159;
    }
  }
  .message{
    white-space: nowrap;
  }
  .title {
    width: fit-content;
    line-height: 30px;
    font-weight: 600;
    font-size: 1.6em;
  }
  .resend-button{
    text-transform: none;
    padding:0;
  }
  .mfa-code-input-container{
    padding-top:5px;
    padding-bottom:5px;
    justify-content: center;
    display: flex !important;
    flex-direction: row;
    gap:2%;

    .mfa-code-input{
      border: none;
      border:solid 1px #ccc;
      border-radius: 10px;
      width: 12% !important;
      min-width: 53px;
      max-width: 65px;
      text-align: center;
      font-size: 4rem;
      aspect-ratio: 1/1.4;
      caret-color: transparent;
      &[type="number"]{
        -moz-appearance: textfield;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::-moz-selection {
        background: transparent;
      }

      &::selection {
        background: transparent;
      }
    }
  }
}