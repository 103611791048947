.lifeguard-creation-pane{
  width: 100%;
  min-height: 5%;
  padding-bottom: 1%;
  height: fit-content;
  border-bottom: 1px solid #3D5484;
  display: block;
  align-items: center;
  flex-direction: row;
  padding-left: 2%;
  padding-right: 2%;
  .MuiAccordion-root{
    width: 96%;
    background-color:transparent;
    color: #EAFBFF;
    font-family: "Work Sans" !important;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
  }
  .actions-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right:0;
    padding-right: 8px;
    .width-30p{
        width: 30%;
    }
    .couple{
      padding-left: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: fit-content;
      gap: 15px;
      .button{
        background-color: rgba(220,124,63,1);
        width: 112px;
        height: 25px;
        border-radius: 4px;
        padding: 5px;
        transition: background-color 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

        &:hover{
          background-color: rgb(238, 149, 93);
          transition: background-color 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        }
        &:active{
          transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
          box-shadow: rgba(110,62,13,1) 0px 0px 3px 1px inset;
        }

      }
    }
  }
  .flexEnd{
    display: flex;
    justify-content: flex-end;
  }
  .MuiAccordionSummary-root{
    margin-top:5px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 1.1rem;
    min-height: 1px !important;
    max-height: 1.875rem;
    &:hover{
      color: #EAFBFF;
      background-color: #001f48;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
    }
  }
  .MuiAccordionSummary-root .Mui-expanded{
    margin-top:0px;
    margin-bottom: 0px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

}

.MuiInputLabel-root{
  color:#AABCCC !important;
  font-size: 16px !important;
}
.lifeguard-pane{
  width: 100%;
  height: fit-content;
  display: flex;
  gap:24px 24px;
  flex-direction: column;
  justify-content: space-around;
  background-color: transparent;
  .text-fields-row{
    display: flex;
    flex-direction: row;
    gap:24px;
  }

}

.MuiFormHelperText-root.Mui-error{
  margin-right: -5px;
  margin-left:2px;
  text-align: left;
}