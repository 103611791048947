#image-carousel {
  width: 100%;
  border: 1px solid #455;
  display: inline-flex;

  .empty-image-view {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    height: 100%;
    z-index: 1;
    position: relative;
    width: 65%;
    padding-left: 65px;
    display: inline-flex;
    justify-content: space-between;

    .left-right-button {
      position: absolute;
      align-items: center;
      height: 100%;
      width: 500px;
      background-color: transparent;
      z-index: 3;
      background-image: linear-gradient(90deg, rgb(255, 255, 255) 0, rgba(255, 255, 255) 30%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 95%, rgb(0, 0, 0,0) 100%);
      display: flex;
      transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

      .button-icon {
        height: 70px;
        width: 70px;
        border-radius: 50px;
        border: 1px solid #00173D;
        color: #00173D;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

        &:hover {
          color: white;
          background-color: #00173D;
          transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

        }
      }
    }

    .left55 {
      margin-left: 9%;
    }

    .image-view {
      width: 100%;
      display: inline-flex;
      align-items: center;
      overflow-x: hidden;
      position: relative;
      transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

      .card {
        cursor: pointer;
        z-index: 1;
        padding: 4px;
        position: absolute;
        width: 250px;
        left: calc(50% - 120px);
        border-radius: 5px;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        transform: scaleX(0.5) scaleY(0.5);

        .inner-text-box {
          display: inline-flex;
          width: fit-content;
          align-items: center;
          height: 40px;
          padding: 5px;

          .alert-type {
            height: 15px;
            width: 15px;
            background-color: #739170;
            border-radius: 15px;
            border: 1px solid #818ea4;
          }
          .warning{
            background-color: #d92d2d;
          }
          .attention{
            background-color: #ecdf56;
          }
          .overcrowding{
            background-color: #0f6faf;
          }
          .title {
            line-height: 16px;
            font-size: 16px;
            padding: 3px;
            font-weight: 500;
            color: #A8AABC;
            background-color: #fff;
            border-radius: 5px;
          }

          .data {
            padding:0 5px 0 8px;
            font-size: 16px;
            font-weight: 500;
            line-height: 14px;
            color: #00173D;
            background-color: #fff;
          }
        }

        .alert-info {
          height: 50px;
          justify-content: space-between;
        }

        .no-image-icon {
          padding: 50px 100px;
        }

        .alert-image {
          height: 200px;
          max-width: 100%;
          border-radius: 5px;
        }
      }

      .next {
        z-index: 2;
        box-shadow: 3px 3px 8px 2px rgba(100, 100, 100, 0.3);
        left: calc(50% + 200px);
        min-height: 250px;
        width: 300px;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        transform: scaleX(1) scaleY(1);

        &:active {
          box-shadow: 1px 1px 4px 0px rgba(100, 100, 100, 0.2);
        }

        &:hover {
          transform: scaleX(1) scaleY(1);
        }
      }

      .prev {
        z-index: 2;
        box-shadow: 3px 3px 8px 2px rgba(100, 100, 100, 0.3);
        left: calc(50% - 500px);
        width: 300px;
        min-height: 250px;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        transform: scaleX(1) scaleY(1);

        &:active {
          box-shadow: 1px 1px 4px 0px rgba(100, 100, 100, 0.2);
        }

        &:hover {
          transform: scaleX(1) scaleY(1);
        }
      }

      .selected {
        align-self: center;
        width: 300px;
        min-height: 250px;
        position: relative;
        left: calc(50% - 150px);
        z-index: 3;
        box-shadow: 3px 3px 8px 2px rgba(100, 100, 100, 0.3);
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        transform: scaleX(1) scaleY(1);

        &:hover {
          transform: scaleX(1.05) scaleY(1.05);
        }
      }

      .inactive {
        left: calc(50% - 120px);
        z-index: 1;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        transform: scaleX(0.1) scaleY(0.1) rotateX(90deg);
      }
    }
  }
}