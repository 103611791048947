#table-pagination-toolbar{
  max-width: 1000px;
  width:100%;
  .toolbar-container{
    height: 53px;
    box-shadow: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .plain-text{
      margin-left: 4%;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: #8C939A;
    }
    .pagination-actions{
      position: absolute;
      right: 0;
    }
  }
}