#alerts-settings-tab {
  .pane {
    gap: 10px;
    padding: 24px;
    display: flex;
    flex-flow: column;
    width: 94.5%;
    .dynamic-pane{
      .add-icon {
        width: fit-content;
        border-radius: 50%;
        display: flex;
        justify-self: flex-start;
        align-self: flex-start;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        cursor: pointer;
        &:hover {
          background: rgba(61, 84, 132, 0.56);
          transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
        }

        &:active {
          background: rgba(61, 84, 132, .1);
        }
      }
      background: #373d19;
      width:100%;
      height: 24px;

    }

    .title {
      padding: 0;
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 10px;
      color: #EAFBFF;
      border: none;
    }

    .alert-mask-pane {
      border: 1px solid #3D5484;
      min-height: 85px;
      gap: 5px;
      margin-top: 20px;
      padding: 22px 10px 10px;
      display: grid;
      align-items: center;
      max-height: 600px;
        overflow-y: auto;

      .alert-mask {
        align-items: center;
        height: 35px;
        .delete-icon {
          border-radius: 50%;
          color: #d92d2d;
          cursor: pointer;
          transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

          &:hover {
            background: rgba(61, 84, 132, 0.56);
            transition: all 100ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
          }

          &:active {
            background: #d92d2d;
            color: #EAFBFF;

          }
        }
        .disabled{
          cursor: default;
          background: rgba(61, 84, 132, 0.56);
            color: #AABCCC;
        }
      }

      .length-25 {
        width: 25px;
      }

      table {
        border-collapse: collapse;
        color: #EAFBFF;
        .header-row {
          border-bottom: 1px solid #3D5484;
          height: 35px;
        }
        th {
          text-align: start;
        }

        td {
          text-align: start;
        }

      }

      tbody {
        max-height: 250px;
        overflow-y: auto;
        height: 100%;

        .center-items {
          text-align: center;
        }
        .length25{
            width: 25px;
        }
      }

    }
  }

  .MuiSvgIcon-root {
    color: #AABCCC;
  }

  input::placeholder {
    opacity: 1;
    color: #AABCCC;
  }
}

