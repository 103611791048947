#monitor-page {
  height: 100%;
  width: 100%;

  .main-pane {
    height: calc(100% - 80px);
    width: 100%;
    margin-top: 80px;
    display: inline-flex;

    .right-pane {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .pane{
        padding: 35px;
        height: 45%;
        flex-grow: 1;
        align-content: center;
        display: flex;
        align-items: center;
        gap: 5%;
        justify-content: space-between;
        background-color: #fff;
      }
    }

    .lx-logo-container-monitor {
      width: 27%;
      height: 100%;
      background: #00173D;
      display: flex;
      flex-flow: column;
      justify-content: center;

      .lx-logo {
        margin-bottom: 120px;
      }
    }
  }
}