* { padding: 0; margin: 0; }
@font-face {
  font-family: 'Work Sans';
  src: local('./public/fonts/WorkSans-Regular.woff'), url('./public/fonts/WorkSans-Regular.woff') format('woff');
}

#root{
  height: 100%;
  overflow: hidden;
}
.padding15{
  padding:15px;
}
.padding30{
  padding:30px;
}
