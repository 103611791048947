body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Work Sans', 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale;  /* Firefox */
  -ms-font-smoothing: antialiased;     /* Older Edge */
}
html, body, #root {
  height: 100%;
  max-width: 100%;
  overscroll-behavior: none;
  -ms-scroll-limit: none; /* Older Edge */
  cursor: default;
  background: #F7F8FC;
}

body::-ms-scrollbar {
  display: none; /* for Edge and IE */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
