#shelf-page {
  flex-direction: column;

  .header {
    background: #ffffff;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    height: 80px;
    border: 1px solid #E6E8EB;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.11);

    .header-text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      text-transform: capitalize;
      color: #00173D;
      padding-left: 3%;
      display: flex;
      align-items: center;
    }

    .close-button {
      color: #3F3F3F;
    }
  }

  .main-pane {
    top: 80px;
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
    width: 100%;
    height: calc(100vh - 82px);

    .minor-header {
      display: inline-flex;
      justify-content: space-between;

      .active-watches-legend {
        display: inline-flex;
        justify-content: space-evenly;
        align-items: center;
        margin-right: 2%;
        margin-top: 2%;
        width: 250px;

        .legend-container {
          display: inline-flex;
          align-items: center;
          width: 110px;

          .plain-text {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #00173D;
          }

          .cube {
            width: 12px;
            height: 12px;
            border-radius: 2px;
          }

          .light-blue {
            border: 1px solid #57A6DB;
            background: #57A6DB;
          }

          .navy {
            border: 1px solid #1F628F;
            background: #1F628F;
          }
        }

      }
    }

    .data {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .safety-event-bottom-container {
      display: inline-flex;
      width: 100%;
    }

    #connected-watches-page {
      margin: 3% 5%;
      width: unset;
      max-width: unset;
    }

    #swimmers-count-history-tile {
      margin-left: 4%;
      margin-right: 4%;
      margin-top: 3%;

      width: unset;
      max-width: unset;

      .container {
        .tile-title {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          margin-top: unset;
        }
      }
    }

    #heatmap-page {
      margin-left: 4%;
      margin-right: 4%;
      margin-top: 3%;
      width: unset;
    }

    #safety-event-tile {
      padding: 0px;
      margin-top: 4%;
      margin-right: 4%;
      margin-bottom: 1%;
      height: unset;
      width: 320px;
      box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
      border-radius: 10px;

      .container {
        .right-inner-pane {
          width: 200px;
        }

        .left-inner-pane {
          top: 10px;
          width: 200px;
          display: flex;
          justify-content: center;
          .circle-chart {
            transform: unset;
          }
          .major-title-container {
            margin-left: unset;
          }
        }

      }
    }

    #reports-table-page {
      margin: 4% 4% 1%;
      width: unset;
      max-width: unset;
      flex-grow: 1;
    }

    #date-picker {
      margin-left: 4%;
      margin-top: 2.5%;
      position: relative;
      border-radius: 5px;

      .calendar-title .date-value {
        font-family: 'Work Sans';
        font-size: 16px;
        font-weight: 500;
        padding-top: 2.5mm;
      }

      .calendar-tile {
        margin-top: 3%;

        .submit {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
        }

        .cancel {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
        }

        left: 0;
      }
    }
  }
}


