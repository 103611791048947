#risk-level-tile {
  width: 200px;
  padding: 19px;
  background-color: #ffffff;
  position: relative;
  border-radius: 5px;
  .alerts-temp-disabled-icon{
    width: 50px;
    height: 50px;
    right: 17%;
    top: 25px;
    position: absolute;
  }
  .shadow {
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  }
  .title {
    position: absolute;
  }
  .info-hover-area{
    width: 80%;
    height: 80%;
    position: absolute;
    z-index: 1;
    .pane{
      position: initial;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
  .tooltip-tile {
    background: #ffffff;
    padding: 15px;
    width: 150px;
    height: 100px;
    font-size: 11px;
    position: absolute;
    z-index: 2;
    box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  }
  .graph-area{
    display: flex;
    justify-content: center;
    .techometer {
      padding: 30px 0 2px;
      position: relative;
    }
    .arrow-small {
      position: absolute;
      transform: rotateZ(3deg) translateX(-27px) translateY(0px);
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      margin: 31.5mm 17mm 28mm 17.2mm;
      width: 70px;
    }
    .arrow-big {
      position: absolute;
      transform: translateX(-27px) translateY(0px);
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      margin: 31mm 15mm 36mm 16mm;
      width: 80px
    }
  }
  .value-title-container {
    margin-top: 10mm;
    .value-title {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}