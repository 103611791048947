.lifeguard-list-container{
  display: flex;
  flex-direction: column;
  gap:1%;
  height:100%;
  //overflow-y:hidden;
  width:100%;
  font-family: 'Work Sans';
  padding: 24px calc(2% + 16px) 0 calc(2% + 16px);
  .header{
    width: calc(100% - calc(2% + 16px) - calc(2% + 16px));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color:#9DADBC;
    align-items: center;
  }

  .lifeguard-list-pane{
    position:relative;
    width: calc(100% - calc(2% + 16px) - calc(2% + 16px));
    .spinner-container{
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
  }
}
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

