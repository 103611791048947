#report-page {
  width: 60%;
  border-radius: 5px;
  min-height: 30%;
  max-height: 80%;
  background: #f7f8fc;
  padding: 40px 40px  100px 40px;
  position: relative;

.container-centered{
  background: rgba(41, 164, 6, 0.1);
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  display: flex;
  justify-content: center;
align-items: center;
  margin-top: 5%;
  height: 230px;
  border-radius: 4px;
  width: 100%;
}
  .report-title {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: 1px solid ;
  }
.title1{
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 0 0 0 10px;
}
  .title2 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 0 0 0 10px;

  }

  .errors-container {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    max-height: 55vh;
    overflow: scroll;
    border: 1px solid rgba(243, 101, 101, 0.24);


  }

  .report-footer {
    width: fit-content;
    display: inline-flex;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 40px;
    justify-content: flex-end;

  }
}