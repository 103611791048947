#safety-event-tile {
  padding:  20px;
  width: 360px;
  height: 250px;
  background: #ffffff;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  max-width: 380px;
  border-radius: 5px;
  .navy-blue {
    background: #1F628F;
  }

  .blue {
    background: #57A6DB
  }

  .light-blue {
    background: #C4E0F2
  }

  .container {
    height: 94.5%;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .left-inner-pane {
      position: relative;
      float: left;
      height: 95%;
      width: 50%;
      padding-top: 5px;
      .tooltip{
        width: 45mm;
        height: fit-content;
        padding: 2mm;
        background: white;
        border-radius: 5px;
        z-index: 1;
        position: absolute;
        box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
        .tooltip-title-container{
          display: inline-flex;
          width: 100%;
          height: 18px;
        }
        .tooltip-title{
          font-weight: 600;
        }
        .pool-row{
          padding: 2mm ;
          display: flex;
          justify-content: space-between;
          .pool-name{
            font-weight: 400;
            font-size: 14px;
          }
          .pool-value{
            font-weight: 600;
            font-size: 15px;
          }
        }
      }
      .major-title-container {

        height: 27mm;
        width: 27mm;
        border-radius: 15mm;
        margin-top: 13mm;
        margin-left: 9mm;
        background: #F9F7F4;
        position: absolute;

        .major-title {
          margin-top: 7mm;
          font-weight: 500;
          font-size: 40px;
          text-align: center;
        }

        .sub-major-title {
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: #727A82;
        }
      }

      .circle-chart {
        width: 200px;
        height: 200px;
        position: absolute;
        left: 0;
        display: grid;
        place-content: center;
        transform: translateX(-7.5%);
      }

      .bottom-container {
        display: flex;
        width: fit-content;
        justify-content: space-around;
        position: absolute;
        bottom: 0;

        .arrow-icon {
          transform: rotateZ(180deg);
          padding-left: 8px;
        }

        .safety-bottom-label {
          padding: 3px;
          bottom: 1mm;
          font-weight: 400;
          font-size: 14px;
          width: fit-content;
          position: relative;
          white-space: nowrap;
        }
      }
    }
    .bullet {
      width: 12px;
      height: 12px;
      border-radius: 2px;
    }
    .wh-10{
      width: 10px;
      height: 10px;
    }
    .m-top8{
      margin-top: 8%;
    }
    .m-top1{
      margin: 4px 10px 0 8px;
    }
    .right-inner-pane {
      height: 60%;
      width: 50%;
      position: relative;
      margin-top: -22px;
      .inner-container {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: stretch;
        justify-content: space-evenly;
        .item-row {
          display: flex;
          height: 10mm;
          padding: 2mm 0;
          justify-content: space-between;
          position: relative;
          .group1{
            display: inline-flex;
            justify-content: flex-start;
            width: 80%;
          }
          .item-title {
            margin-top: 5%;
            margin-left: 3mm;
            font-size: 18px;
            line-height: 21px;
            text-align: start;
            letter-spacing: 0.4px;
            width: 70%;
          }

          .item-value {
            margin-top: 5%;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
          }
        }

      }
    }
  }

  .more-action {
    float: right;
    position: relative;
    color: #727A82;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

    &:hover {
      background: #eee;
      border-radius: 25px;
    }
  }
}