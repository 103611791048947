
#main-pane{
  width: 100%;
  padding-bottom: 20px;
  height: fit-content;
  border-bottom: 1px solid #3D5484;
  display: block;
  align-items: center;
  flex-direction: row;

  .MuiAccordion-root{
    width: 100%;
    background-color:transparent;
    color: #EAFBFF;
    font-family: "Work Sans" !important;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
  }
  .actions-row{
    display: flex;
    gap:12px;
    width: 100%;
    justify-content: flex-end;
    margin-right:0;
    padding-right: 8px;
    .save-button{
      background-color:#57A6DB;
      color: #00173D;
      font-size: 14px;
      text-transform: none;
      font-family: "Work Sans";
      padding: 5px 15px;
      &:disabled{
        background-color: #3D5484;
        color: #AABCCC;
      }
    }
  }

  .MuiAccordionSummary-root{
    margin-top:5px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 1.1rem;
    min-height: 1px !important;
    max-height: 1.875rem;
    &:hover{
      color: #EAFBFF;
      background-color: #001f48;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
    }
  }
  .MuiAccordionSummary-root .Mui-expanded{
    margin-top:0px;
    margin-bottom: 0px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

}

.MuiInputLabel-root{
  color:#AABCCC !important;
  font-size: 16px !important;
}
.acc-pane{
  width: 100%;
  height: fit-content;
  display: flex;
  gap:24px 24px;
  flex-direction: column;
  justify-content: space-around;
  background-color: transparent;

  .text-fields-row{
    display: flex;
    flex-direction: row;
    gap:4px;
    .select-value{
      color: #EAFBFF;
    }
  }

}

.MuiFormHelperText-root.Mui-error{
  margin-right: -5px;
  margin-left:2px;
  text-align: left;
}