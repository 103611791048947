#life-guard-reports{
  padding: 20px;
  width: 270px;
  background: #ffffff;
  height:250px;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  .bottom-container{
    display: flex;
    justify-content: center;
    .arrow-icon{
      padding-left: 8px;
      transform: rotateZ(180deg);
    }
    .bottom-label-lg{
      padding: 3px;
      bottom: 0.5mm;
      font-weight: 400;
      font-size: 14px;
      width: fit-content;
      position: relative;
    }
  }
  .container{
    height: 84%;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    .tooltip{
      border-radius: 5px;
      width: 45mm;
      height: fit-content;
      padding: 2mm;
      background: white;
      z-index: 2;
      position: absolute;
      box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
      .pool-row{
        padding: 2mm ;
        display: flex;
        justify-content: space-between;
        .pool-name{
          font-weight: 400;
          font-size: 14px;
        }
        .pool-value{
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
    .hover-pane{
      width:60%;
      height: 80%;
      margin-top: 10%;
      border-radius: 40px;
      background: transparent;
      z-index: 0;
    }
    .major-title-container{
      height: fit-content;
      width: fit-content;
      position: absolute;
      bottom:17mm;
      z-index: 0;

      .major-title{
        font-weight: 500;
        font-size: 40px;
        text-align: center;
      }
      .sub-major-title{
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #727A82;
      }
    }
    .clip-board{
      position: absolute;
      bottom:3mm;
    }
  }
  .more-action{
    float:right;
    position:relative;
    color: #727A82;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
    &:hover{
      background: #eee;
      border-radius: 25px;
    }
  }
}