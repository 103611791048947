#main-header{
background-color: #00173D;
  height: 80px;
  width: 100%;
  color: #F9F7F4;
  font-size: 18px;
  top: 0;
  z-index: 1;
  position: absolute;
  .company-icon{

    max-width:175px;
    max-height: 55px;
    padding:12px 20px 0 15px;
    float:left;
  }
  .vertical-separator{
    height: 34px;
    width: 1px;
    vertical-align: center;
    border-left: 1px solid #727A82;
    margin-top:23px;
  }
  .right{
    float: right;
  }
  .left{
    float: left;
  }
}